import axios, { AxiosResponse } from "axios";
import { Block, Page } from "../models/directus";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export default class DirectusServices {
  public async getBlocks(): Promise<Block[]> {
    let data;
    try {
      const res: AxiosResponse<{ data: Block[] }, any> = await axios.get(
        "/items/blocks"
      );
      data = res.data.data;
    } catch (err: any) {
      throw new Error(err.message + "Something went wrong, please try again.");
    }
    return data;
  }

  public async getBlocksByPageId(id: number): Promise<Block[]> {
    let data;
    try {
      const res: AxiosResponse<{ data: Block[] }, any> = await axios.get(
        `items/blocks?filter[page][_eq]=${id}`
      );
      data = res.data.data;
    } catch (err: any) {
      throw new Error(err.message + "Something went wrong, please try again.");
    }
    return data;
  }

  public async getPages(): Promise<Page[]> {
    let data;
    try {
      const res: AxiosResponse<{ data: Page[] }, any> = await axios.get(
        "/items/pages"
      );
      data = res.data.data;
    } catch (err: any) {
      throw new Error(err.message + "Something went wrong, please try again.");
    }
    return data;
  }

  public async getPageById(id: number): Promise<Page> {
    let data;
    try {
      const res: AxiosResponse<{ data: Page[] }, any> = await axios.get(
        `items/pages/${id}`
      );
      data = res.data.data;
    } catch (err: any) {
      throw new Error(err.message + "Something went wrong, please try again.");
    }
    return data[0];
  }

  public async getPageByPath(path: string): Promise<Page> {
    let data;
    try {
      const res: AxiosResponse<{ data: Page[] }, any> = await axios.get(
        `items/pages?filter[path][_eq]=${path}`
      );
      data = res.data.data;
    } catch (err: any) {
      throw new Error(err.message + "Something went wrong, please try again.");
    }
    return data[0];
  }

  public async getPageChildren(id: number): Promise<Page[]> {
    let data;
    try {
      const res: AxiosResponse<{ data: Page }, any> = await axios.get(
        `items/pages/${id}/?fields=id,Name,Slug,parent,path,children.Name,children.id,children.path`
      );
      data = res.data.data;
    } catch (err: any) {
      throw new Error(err.message + "Something went wrong, please try again.");
    }
    return data.children as Page[];
  }
}
