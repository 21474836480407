import { useRoute } from "vue-router";
import DirectusService from "@/services/services";

export default async function getDirectus() {
 
const directusService = new DirectusService();
const route = useRoute();
const path = route.path;

const pageData = await directusService.getPageByPath(path);
const blocks = await directusService.getBlocksByPageId(pageData.id);
const children = await directusService.getPageChildren(pageData.id);

  return {
    pageData,
    blocks,
    children,
  };
}